import React from "react";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";

export default () => {
  const title = "Thank you for contacting Schoolhouse by the Sea!";
  const description =
    "We appreciate you reaching out to us and will follow up with you as soon as we can.";
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1>Thank you!</h1>
          </div>
        </div>
      </section>
    </Layout>
  );
};
